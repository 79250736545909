import { createGlobalStyle } from 'styled-components';

const Style = createGlobalStyle`
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 0% 200%;
  src: local('Inter'), url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
}

html {
  margin:0;
  padding:0;
}

body {
  margin: 0;
  font-family: Inter,BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #373737;
  background-color: #F9F9F9;
  overflow-x: hidden;
  overscroll-behavior: contain;

  font-size: 0.875rem;
}

p {
  margin-top: 16px
}

/** Customize scrolling globally */
::-webkit-scrollbar {
  width: 4px;
};

/* Track */
::-webkit-scrollbar-track {
  background: #FFF;  
};
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
};
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
};
`;

export default Style;
