import { useCallback, useContext } from 'react';

import { FeatureFlagContext } from './feature.context';
import { UseFeatureFlagApi } from './feature.types';

/**
 * Reusable hook to provide feature flag functionality
 *
 * @typedef UseFeatureFlagApi
 * @returns {UseFeatureFlagApi} Function to be used to determine whether a feature is enabled or not.
 */
export function useFeatureFlag(): UseFeatureFlagApi {
  const featureContext = useContext(FeatureFlagContext);
  if (!featureContext) {
    throw new Error('Have you register the feature mapping using the FeatureProvider?');
  }

  const { featureMap, environment, appContext } = featureContext;
  const isEnabled = useCallback(
    (feat: string) => {
      // By default every feature is enabled. This allows a cleaner feature map file
      const feature = featureMap[feat];
      if (!feature) {
        return true;
      }

      // If we don't care about the environment and the context, we can simply define a boolean value
      if (feature.enabled === true || feature.enabled === false) {
        return feature.enabled;
      }

      // When the environment is provided, we check for it, otherwise is true by default
      let isEnabledFromEnv = true;
      if (environment && 'environment' in feature.enabled) {
        isEnabledFromEnv =
          feature.enabled.environment.find((e) => e === environment) != null;
      }

      // When the context is provided, we check for it, otherwise is true by default
      let isEnabledFromContext = true;
      if (appContext && 'context' in feature.enabled) {
        isEnabledFromContext =
          feature.enabled.context.find((c) => c === appContext) != null;
      }

      // The feature is enabled when all conditions are true.
      return feature.enabled.value && isEnabledFromContext && isEnabledFromEnv;
    },
    [appContext, environment, featureMap]
  );

  return { isEnabled };
}

export default useFeatureFlag;
