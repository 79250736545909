export const DS_BASE_URL = 'https://api-dev.expedo.team';
export const DS_DEFAULT_TIMEOUT = 30000;
export const DS_URL_PREFIX = 'api/v1';

export const DsAPI = {
  currentUser: '/users/me',
  personas: '/personas/me',
  companies: '/companies',
  companyById: (id: number) => `/companies/${id}`,
  instances: '/instances',
  instanceById: (id: number) => `/instances/${id}`,
  companiesByInstanceId: (id: number) => `/instances/${id}/companies`,
  navigation: '/nav',
};
