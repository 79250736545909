import { CustomAxios, CustomAxiosOptions } from '@expedo/custom-axios';
import { useContext, useMemo } from 'react';

import { DirectoryServices } from './directory-services';
import { DSContext } from './directory-services.context';

export const useDirectoryServices = (config?: CustomAxiosOptions) => {
  const context = useContext(DSContext);
  if (!context) {
    throw new Error(
      'useDirectoryServices must be used as a child of DirectoryServicesProvider'
    );
  }

  return useMemo(() => {
    const client = new CustomAxios(config).client();
    return new DirectoryServices({ client, baseUrl: context.url });
  }, [config, context.url]);
};
