import axios, { AxiosHeaders, AxiosInstance, CreateAxiosDefaults } from 'axios';

import { handleTokenFromConfig } from './custom-axios.helper';

export type CustomAxiosOptions = CreateAxiosDefaults & {
  authToken?: string;
  tokenFromStorage?: 'local' | 'session';
};

export class CustomAxios {
  private _client: AxiosInstance;
  private _config?: CustomAxiosOptions;

  constructor(config?: CustomAxiosOptions) {
    this._client = axios.create(config);
    this._config = config;
    this.addRequestInterceptor();
  }

  client() {
    return this._client;
  }

  private addRequestInterceptor() {
    this._client.interceptors.request.use(async (request) => {
      const headers = new AxiosHeaders();

      if (request.url?.includes('profile-photo')) {
        headers.set('Accept', 'image/*');
        headers.set('Content-Type', 'image/*');
        headers.set('Authorization', 'Sass-Key abcd');
        request.headers = request.headers
          ? Object.assign(request.headers, headers)
          : headers;

        return request;
      }

      const authToken = handleTokenFromConfig(this._config);

      headers.set('Authorization', `Bearer ${authToken}`);
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');

      if (request.data instanceof FormData) {
        headers.set('Content-Type', 'multipart/form-data');
      }

      request.headers = request.headers
        ? Object.assign(request.headers, headers)
        : headers;
      return request;
    });
  }
}
