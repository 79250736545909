import { PropsWithChildren } from 'react';
import { DSContext } from './directory-services.context';
import { DSProviderProps } from './directory-services.types';

/**
 * This provider is meant to be applied at the root of the application.
 *
 * @example
 * import DirectoryServicesProvider from '@expedo/directory-services';
 *
 * const App = () => {
 *     <DirectoryServicesProvider url={environment.directoryServicesUrl}>
 *       <MyComponent />
 *     </DirectoryServicesProvider>
 *   );
 * };
 *
 * @param children The rest of the react nodes
 * @returns
 */
export function DirectoryServicesProvider(props: PropsWithChildren<DSProviderProps>) {
  return <DSContext.Provider value={props}>{props.children}</DSContext.Provider>;
}

export default DirectoryServicesProvider;
