export type User = {
  id?: string;
  firstName: string | null;
  lastName: string | null;
  primaryEmail?: string | null;
  profilePhoto?: string | null;
  options?: Options;
};

export type Persona = {
  id: string;
  userId: string;
  roleCodes: RoleCode[] | null;
  credentialId?: string;
  instanceId: number;
  companyId?: number | null;
  options?: Options;
  email?: string | null;
};

export type Instance = {
  id: number;
  name: string | null;
  subscriptionId: number;
  modules: Module[];
};

export interface Company {
  id: number;
  name: string;
  instanceId: number;
  logoUrl?: string | null;
  options?: Options;
}

export type Options = Record<string, unknown>;

export type Module = {
  instanceId: number;
  moduleType: ModuleType;
  url: string;
};

export enum ModuleType {
  None = 0,
  HR = 1,
  GP = 2,
  Hire = 3,
  Timesheets = 4,
  Migration = 5,
}

export enum RoleCode {
  Administrator = 'Administrator',
  Manager = 'Manager',
  HRManager = 'HRManager',
  CompanyAdmin = 'CompanyAdmin',
  ClientAdmin = 'ClientAdmin',
  Worker = 'Worker',
  Recruiter = 'Recruiter',
  PayrollManager = 'PayrollManager',
  PSPAdmin = 'PSPAdmin',
  CompanyAccountant = 'CompanyAccountant',
  GlobalAdmin = 'GlobalAdmin',
}
